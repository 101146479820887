import axios from 'axios'

const store = {
  namespaced: true,

  state: {
    active: false,
    params: false
  },
  getters: {
    params: state => state.params,
    active: state => state.active
  },
  mutations: {
    SET_ACTIVE (state, snackbar) {
      state.active = snackbar
    },
    SET_PARAMS (state, parameters) {
      state.params = parameters
    },
    CLOSE (state, snackbar) {
      state.active = false
      state.params = false
    },
  },
  actions: {
    open ({ commit }, snackbar) {
      if (typeof snackbar === 'object') {
        commit('SET_PARAMS', snackbar.params)
        snackbar = snackbar.name
      }

      commit('SET_ACTIVE', snackbar)
    },
    close ({ commit }) {
      commit('CLOSE')
    },
  },
}

export default store
