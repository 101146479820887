const store = {
  namespaced: true,

  state: {
    mode: null,
  },
  getters: {
    mode: state => state.mode,
  },
  mutations: {
    SET_STATE (state, { key, value }) {
      state[key] = value
    }
  },
  actions: {
    setScanMode ({ commit }, mode) {
      commit('SET_STATE', { key: 'mode', value: mode })
    }
  },
}

export default store
