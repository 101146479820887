import axios from 'axios'
import * as types from './types'

const store = {
  /**
   * State
   * @type {Object}
   */
  state: {
    activeTab: '/dashboard',
    snackBar: {
      active: false,
      color: 'green',
      buttonColor: 'primary',
      timeout: 2000,
      text: null
    }
  },

  /**
   * [getters description]
   * @type {Object}
   */
  getters: {
    activeTab: state => state.activeTab,
    isSnackBarActive: state => state.snackBar.active,
    snackBarColor: state => state.snackBar.color,
    snackBarButtonColor: state => state.snackBar.buttonColor,
    snackBarTimeout: state => state.snackBar.timeout,
    snackBarText: state => state.snackBar.text,
  },

  /**
   * Mutations
   * @type {Object}
   */
  mutations: {
    [types.SET_ACTIVE_TAB] (state, activeTab) {
      state.activeTab = activeTab
    },
    [types.SET_ACTIVE_SNACKBAR] (state, snackBar) {
      state.snackBar = snackBar
    }
  },

  /**
   * Actions
   * @type {Object}
   */
  actions: {
    setActiveTab ({ commit}, activeTab) {
      commit(types.SET_ACTIVE_TAB, activeTab)
    },
    setActiveSnackbar ({ commit }, snackBar) {
      commit(types.SET_ACTIVE_SNACKBAR, snackBar)
    }
  }
}

export default store
