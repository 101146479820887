import axios from 'axios'

const store = {
  namespaced: true,

  state: {
    barangayLogs: {
      categories: [],
      series: []
    },
    borderLogs: {
      categories: [],
      series: []
    },
    latestEntryLogs: [],
    latestExitLogs: [],
    visitorExitLogs: [],
    visitorEntryLogs: [],
  },
  getters: {
    barangayLogs: state => state.barangayLogs,
    borderLogs: state => state.borderLogs,
    latestEntryLogs: state => state.latestEntryLogs,
    latestExitLogs: state => state.latestExitLogs,
    visitorExitLogs: state => state.visitorExitLogs,
    visitorEntryLogs: state => state.visitorEntryLogs,
  },
  mutations: {
    SET_CHART_STATE (state, { key, options }) {
      state[key].categories = options.categories
      state[key].series = options.series
    },
    SET_ENTRY_LOGS (state, data) {
      state.latestEntryLogs = data
    },
    SET_EXIT_LOGS (state, data) {
      state.latestExitLogs = data
    },
    SET_VISITOR_EXIT_LOGS (state, data) {
      state.visitorExitLogs = data
    },
    SET_VISITOR_ENTRY_LOGS (state, data) {
      state.visitorEntryLogs = data
    },
  },
  actions: {
    async getScanLogsPerBarangay ({ commit }, dateRange) {
      const response = await axios.get('/barangay-logs')

      commit('SET_CHART_STATE', { key: 'barangayLogs', options: response.data })

      return response
    },
    async getScanLogsPerBorder ({ commit }, dateRange) {
      const response = await axios.get('/border-logs')

      commit('SET_CHART_STATE', { key: 'borderLogs', options: response.data })

      return response
    },
    async getLatestLogs ({ commit }, [type, limit, query = null]) {
      const response = await axios.get('/latest-logs', {
        params: {
          type: type,
          limit: limit,
          search: query
        }
      })

      if (type) {
        if (type === 'Entrance') {
          commit('SET_ENTRY_LOGS', response.data)
        } else {
          commit('SET_EXIT_LOGS', response.data)
        }
      } else {
        let entry = []
        let exit = []

        response.data.forEach((d) => {
          if (d.scan_type === 'Entrance') {
            entry.push(d)
          } else {
            exit.push(d)
          }
        })

        commit('SET_EXIT_LOGS', exit)
        commit('SET_ENTRY_LOGS', entry)
      }


      return response
    },
    async getVisitorLogs ({ commit }, [type, limit, query = null]) {
      const response = await axios.get('/visitor-logs', {
        params: {
          type: type,
          limit: limit,
          search: query
        }
      })

      if (type) {
        if (type === 'Entrance') {
          commit('SET_VISITOR_ENTRY_LOGS', response.data)
        } else {
          commit('SET_VISITOR_EXIT_LOGS', response.data)
        }
      } else {
        let entry = []
        let exit = []

        response.data.forEach((d) => {
          if (d.scan_type === 'Entrance') {
            entry.push(d)
          } else {
            exit.push(d)
          }
        })

        commit('SET_VISITOR_EXIT_LOGS', exit)
        commit('SET_VISITOR_ENTRY_LOGS', entry)
      }


      return response
    }
  },
}

export default store
