import Vue from 'vue'
import moment from 'moment'

Vue.mixin({
  methods: {
    /**
     * Returns a version of the string parameter where the first character is converted to uppercase
     */
    ucFirst (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },

    /**
     * Returns a formal case version of a string
     */
    toFormalCase (field) {
      if (field.includes('_')) {
        field = field.split('_').join(' ')
      }

      field = field.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase()
      })

      return field
    },

    /**
     * Returns a date and time format
     */
    toReadableDateTime (dateTime) {
      if (dateTime) {
        return moment(dateTime).format('MMMM DD, YYYY hh:mm A')
      }
      return dateTime
    },

    /**
     * returns date format only
     */
    toReadableDate (date) {
      if (dateTime) {
        return moment(dateTime).format('MMMM DD, YYYY')
      }
      return dateTime
    },
  }
})
