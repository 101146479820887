import axios from 'axios'

const store = {
  namespaced: true,

  state: {
    activeModal: false,
    modalParams: false,
    promise: {
      options: null,
      resolve: null,
      reject: null,
    }
  },
  getters: {
    modalParams: state => state.modalParams,
    activeModal: state => state.activeModal
  },
  mutations: {
    ACTIVE_MODAL (state, modal) {
      state.activeModal = modal
    },
    MODAL_PARAMETER (state, parameters) {
      state.modalParams = parameters
    },
    CLOSE_MODAL (state, modal) {
      state.activeModal = false
      state.modalParams = false
    },
    MODAL_PROMISE (state, { resolve, reject }) {
      state.promise.resolve = resolve
      state.promise.reject = reject
    },
  },
  actions: {
    openModal ({ commit }, modal) {
      if (typeof modal === 'object') {
        commit('MODAL_PARAMETER', modal.params)
        modal = modal.name
      }

      commit('ACTIVE_MODAL', modal)
    },
    async confirm ({ commit }, options = {}) {
      commit('ACTIVE_MODAL', 'confirm-modal')
      commit('MODAL_PARAMETER', options)

      return new Promise((resolve, reject) => {
        commit('MODAL_PROMISE', { resolve, reject })
      })
    },

    async resolve ({ commit, state }, response = true) {
      state.promise.resolve(response)

      commit('CLOSE_MODAL')
    },
    closeModal ({ commit }) {
      commit('MODAL_PARAMETER', null)
      commit('CLOSE_MODAL')
    },
  },
}

export default store
