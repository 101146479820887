import Vue from 'vue'
import Vuex from 'vuex'
import admin from './admin'
import scanner from './scanner'
import snackbar from './snackbar'
import dashboard from './dashboard'
import residents from './residents'
import modal from './modal'
import createLogger from 'vuex/dist/logger'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    scanner,
    snackbar,
    residents,
    modal,
    dashboard
  },
  strict: false,
  plugins: debug ? [createLogger()] : []
})
