require('./bootstrap');

import { App, plugin } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueQrcodeReader from "vue-qrcode-reader"
import VueMeta from 'vue-meta'
import './helpers'

Vue.use(VueMeta)
Vue.use(VueQrcodeReader)
Vue.use(Vuetify)
Vue.use(plugin)
InertiaProgress.init()

const el = document.getElementById('app')

var store = require('./store').default

new Vue({
  metaInfo: {
    titleTemplate: title => (title ? `${title} - Tapaz ID System` : 'Tapaz ID System')
  },
  vuetify: new Vuetify(),
  store,
  components: {
    ...require('./components/charts').default,
    ...require('./components/partials').default,
    ...require('./components/modals').default
  },
  render: h => h(App, {
    props: {
      initialPage: JSON.parse(el.dataset.page),
      resolveComponent: name => import(`./pages/${name}`).then(module => module.default),
    },
  }),
}).$mount(el)
